import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { constantToSelect } from 'common/helpers/constantToSelect';
import { Field, FieldArray, Form, getIn } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import FormInputField from '../../common/components/formFields/FormInputField';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import {
  clearPhotosInServiceHistory,
  getDraft,
  removePhotosFromServiceHistory,
  saveServiceHistoryDraft,
  sendServiceHistoryPhotoRecords,
} from '../AddVehicleActions';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS } from '../AddVehicleReducer';
import { resizeImage } from '../../common/utils/image/Image.utils';
import { ServiceHistory, ServiceHistoryName } from '../../constants/ServiceHistory';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import plusIcon from '../../assets/img/plus-icon.png';
import CommonDeleteIconButton from '../../common/components/CommonDeleteIconButton';
import { CommonSweetAlertVariants } from '../../constants/CommonSweetAlertVariants';
import CommonSweetAlert from '../../common/components/CommonSweetAlert';
import AddVehicleFooter from '../AddVehicleFooter';

const serviceHistoryList = constantToSelect(ServiceHistoryName);

const ServiceHistoryForm = ({ prevForm, values, form, nextForm }) => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isShowForm, setShowForm] = useState(false);
  const [isButtonAddDisabled, setButtonAddDisabled] = useState(true);
  const [idDelHistory, setIdDelHistory] = useState(null);
  const [isPhotoLoading, setPhotoLoading] = useState(false);
  const photoRecordsFromValue = values?.photoRecords ? values.photoRecords : [];
  const photoRecordsFromState = Array.from(
    useSelector(state => state.addVehicle.get('photoRecords')),
  );
  const ids = new Set(photoRecordsFromState.map(d => d?.mediaMetadataId));
  const photoRecords = [
    ...photoRecordsFromValue.filter(d => !ids.has(d?.mediaMetadataId)),
    ...photoRecordsFromState,
  ];
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  const handleDeleteServiceHistory = (history, arrayHelpers, index) => {
    if (history) {
      arrayHelpers.remove(index);
    }
  };

  const handleDeletePhotoRecord = id => {
    const removeIndex =
      photoRecords
        .map(function(item) {
          return item.mediaMetadataId;
        })
        .indexOf(id) + 1;
    values.photoRecords = values.photoRecords.filter(e => e.mediaMetadataId !== id);
    if (removeIndex && id) {
      values.photoRecordsToDelete.push(id);
      dispatch({
        type: DELETE_PHOTO_IN_SERVICE_HISTORY_SUCCESS,
        payload: {
          idPhotoRecord: removeIndex,
        },
      });
    }
    dispatch(removePhotosFromServiceHistory(id));
  };

  const handleUploadPhoto = async e => {
    const file = e.target.files[0];
    const imageResized = await resizeImage(file);
    if (imageResized && imageResized?.type.includes(UploadFileTypes.IMAGE)) {
      setPhotoLoading(true);
      await dispatch(sendServiceHistoryPhotoRecords(imageResized)).catch(() => {});
      setPhotoLoading(false);
    }
  };

  const clearHistoryRecords = useCallback(() => {
    if (values?.type?.value === ServiceHistory.NONE) {
      values.manualRecords = [];
      values.photoRecordsToDelete = [...values.photoRecords.map(el => el.mediaMetadataId)];
      values.photoRecords = [];
    }
  }, [
    values.manualRecords,
    values.photoRecords,
    values.photoRecordsToDelete,
    values.type.value,
  ]);

  const handleSaveDraft = () => {
    clearHistoryRecords();
    for (let i = 0; i < values?.manualRecords?.length; i++) {
      values.manualRecords[i].date = reverseDateToString(values.manualRecords[i].date);
    }
    values.photoRecords = photoRecords;
    const data = {
      ...values,
      type: values?.type?.value || null,
    };
    dispatch(
      saveServiceHistoryDraft(data, () => {
        dispatch(getDraft(id));
      }),
    );
  };

  const handleAddAnotherService = arrayHelpers => {
    arrayHelpers.push({
      date: '',
      mileage: '',
      serviceName: '',
    });
  };

  useEffect(() => {
    let manualRecordsAllowed = true;
    for (let i = 0; i < values?.manualRecords.length; i++) {
      if (
        isEmpty(values?.manualRecords[i]?.date) ||
        isEmpty(values?.manualRecords[i]?.mileage?.toString()) ||
        isEmpty(values?.manualRecords[i]?.serviceName)
      ) {
        manualRecordsAllowed = false;
      }
    }
    setButtonAddDisabled(!manualRecordsAllowed);
  }, [values, photoRecords]);

  useEffect(() => {
    if (values?.type?.value === ServiceHistory.NONE) {
      clearHistoryRecords();
      dispatch(clearPhotosInServiceHistory());
    } else if (values?.type?.value !== ServiceHistory.NONE && !values.manualRecords.length) {
      values.manualRecords = [
        {
          date: '',
          mileage: '',
          serviceName: '',
        },
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (
      values?.type?.value === ServiceHistory.FULL ||
      values?.type?.value === ServiceHistory.PARTIAL
    ) {
      setShowForm(true);
    } else if (values?.type?.value === ServiceHistory.NONE) {
      setShowForm(false);
    }
  }, [values, values.type]);

  useEffect(() => {
    clearHistoryRecords();
    for (let i = 0; i < values?.manualRecords?.length; i++) {
      values.manualRecords[i].date = reverseDateToString(values.manualRecords[i].date);
    }
    values.photoRecords = photoRecords;
  }, [
    clearHistoryRecords,
    photoRecords,
    values.manualRecords,
    values.photoRecords,
    values.type.value,
  ]);

  return (
    <>
      <AddVehicleHeader
        saveDraftButtonHandler={handleSaveDraft}
        saveDraftButtonDisabled={isPhotoLoading || isLoading}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Service History</h5>
            <p className="text-sm mt-2">Add details of the vehicle&apos;s service history.</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form autoComplete="off">
              <Row>
                <Col className="ml-auto mr-auto " md={12}>
                  <div className="service-history__container">
                    <Row>
                      <Col sm={12} md={6}>
                        <FormSelectField
                          name="type"
                          label="Service History Type"
                          options={serviceHistoryList}
                          required
                        />
                      </Col>
                    </Row>
                    {values?.type?.value === ServiceHistory.NONE && (
                      <Row className="mt-4">
                        <Col md={6}>
                          <Alert color="error">
                            Are you sure this vehicle has No Service History?
                          </Alert>
                          <Row className="mt-3">
                            <Col className="actions-wrapper" md={12}>
                              <CommonButton
                                label="no"
                                handleClick={() => {
                                  form.setFieldValue('type', '');
                                }}
                                variant={CommonButtonVariants.OUTLINED}
                                className="flex-grow-1"
                              />
                              <CommonButton
                                label="yes"
                                handleClick={() => {
                                  form.handleSubmit();
                                }}
                                className="flex-grow-1"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {isShowForm ? (
                      <>
                        <Row className="mt-4">
                          <Col xs="12">
                            <h5 className="font-weight-bold">Add scans of the service book</h5>
                          </Col>
                          <Col>
                            <Row>
                              {photoRecords.length
                                ? photoRecords.map((item, index) => (
                                    <Col key={item?.mediaMetadataId} md={3} className="p-0">
                                      <div className="photo-summary__service-history-photo-wrapper">
                                        <div className="service-history-photo-wrapper__image-container d-flex justify-content-center align-items-center w-100 h-100 position-relative">
                                          <div className="overflow-hidden h-100 w-100">
                                            <img
                                              key={item?.mediaMetadataId}
                                              src={item?.fileUrl}
                                              alt="Service history"
                                              className="photo-summary__service-history"
                                            />
                                          </div>
                                        </div>
                                        <Row className="mt-1">
                                          <Col className="d-flex flex-row align-items-center">
                                            <span className="photo-summary__title text-sm">
                                              SCAN {index + 1}
                                            </span>
                                            <CommonDeleteIconButton
                                              className="service-history__button ml-auto"
                                              handleClick={() => {
                                                handleDeletePhotoRecord(item?.mediaMetadataId);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  ))
                                : null}
                              <Col md={3} className="p-0">
                                <div className="photo-summary__service-history-photo-wrapper">
                                  <button
                                    type="button"
                                    className="add-photo__base-style photo-summary__add-obligatory-service-history-button position-relative m-0"
                                    disabled={isPhotoLoading}
                                  >
                                    <label
                                      htmlFor="photo-upload"
                                      className="photo-summary__add-photo-upload-label"
                                    >
                                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                        <p className="add-photo__drop-text m-0">
                                          CLICK TO BROWSE OR
                                        </p>
                                        <p className="add-photo__drop-text m-0">
                                          DRAG & DROP PHOTO HERE
                                        </p>
                                        <div className="add-photo__add_center-icon mt-3">
                                          <img
                                            src={plusIcon}
                                            alt="Add"
                                            className={`${
                                              isPhotoLoading
                                                ? 'photo-summary__add-photo-upload-label--loading'
                                                : ''
                                            }`}
                                          />
                                          {!isPhotoLoading && (
                                            <input
                                              id="photo-upload"
                                              type="file"
                                              accept="image/*"
                                              onChange={handleUploadPhoto}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </label>
                                  </button>
                                  <Row className="mt-1">
                                    <Col className="d-flex flex-row align-items-center">
                                      <span className="photo-summary__title text-sm">
                                        SCAN {photoRecords?.length + 1}
                                      </span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xs="12 mb-4">
                            <h5 className="font-weight-bold">or fill the below form</h5>
                          </Col>
                          <Col xs="12">
                            <FieldArray
                              name="manualRecords"
                              render={arrayHelpers => (
                                <div>
                                  {values?.manualRecords.map((service, index) => {
                                    return (
                                      <>
                                        <Row
                                          key={(() => {
                                            return index;
                                          })()}
                                          className="service-history__manual-record"
                                        >
                                          <Col md="12" className="d-flex justify-content-end">
                                            {values?.manualRecords.length > 1 && (
                                              <CommonDeleteIconButton
                                                className="service-history_trash-button"
                                                handleClick={() => {
                                                  setIdDelHistory(index);
                                                }}
                                              />
                                            )}
                                          </Col>
                                          <Col md="3">
                                            <Field name={`manualRecords.${index}.date`}>
                                              {({ form }) => {
                                                const error = getIn(
                                                  form.errors,
                                                  `manualRecords.${index}.date`,
                                                );
                                                const touch = getIn(
                                                  form.touched,
                                                  `manualRecords.${index}.date`,
                                                );
                                                return (
                                                  <div>
                                                    <FormDatePickerField
                                                      name={`manualRecords.${index}.date`}
                                                      label="Date"
                                                      icon={calendarIcon}
                                                      iconClassName="calendar-icon"
                                                      invalid={!!touch && !!error}
                                                      position="top"
                                                      required
                                                    />
                                                    {touch && error ? (
                                                      <label className="error-label">
                                                        {error}
                                                      </label>
                                                    ) : null}
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Col>
                                          <Col md="3">
                                            <Field name={`manualRecords.${index}.mileage`}>
                                              {({ form }) => {
                                                const error = getIn(
                                                  form.errors,
                                                  `manualRecords.${index}.mileage`,
                                                );
                                                const touch = getIn(
                                                  form.touched,
                                                  `manualRecords.${index}.mileage`,
                                                );
                                                return (
                                                  <div>
                                                    <FormInputField
                                                      name={`manualRecords.${index}.mileage`}
                                                      label="Mileage"
                                                      invalid={!!touch && !!error}
                                                      required
                                                    />
                                                    {touch && error ? (
                                                      <label className="error-label">
                                                        {error}
                                                      </label>
                                                    ) : null}
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Col>
                                          <Col md="6">
                                            <Field name={`manualRecords.${index}.serviceName`}>
                                              {({ form }) => {
                                                const error = getIn(
                                                  form.errors,
                                                  `manualRecords.${index}.serviceName`,
                                                );
                                                const touch = getIn(
                                                  form.touched,
                                                  `manualRecords.${index}.serviceName`,
                                                );
                                                return (
                                                  <div>
                                                    <FormInputField
                                                      name={`manualRecords.${index}.serviceName`}
                                                      label="Service name"
                                                      invalid={!!touch && !!error}
                                                      required
                                                    />
                                                    {touch && error ? (
                                                      <label className="error-label">
                                                        {error}
                                                      </label>
                                                    ) : null}
                                                  </div>
                                                );
                                              }}
                                            </Field>
                                          </Col>
                                        </Row>
                                        <CommonSweetAlert
                                          variant={CommonSweetAlertVariants.ERROR}
                                          show={idDelHistory === index}
                                          onConfirm={() => {
                                            handleDeleteServiceHistory(
                                              service,
                                              arrayHelpers,
                                              idDelHistory,
                                            );
                                            setIdDelHistory(null);
                                          }}
                                          onCancel={() => setIdDelHistory(null)}
                                        >
                                          <p className="common-sweet-alert__title">
                                            Are you sure to remove this information about
                                            service history?
                                          </p>
                                        </CommonSweetAlert>
                                      </>
                                    );
                                  })}{' '}
                                  <Row className="mt-3">
                                    <Col sm={12} md={6}>
                                      <CommonButton
                                        type="button"
                                        variant={CommonButtonVariants.OUTLINED}
                                        label="+ Add another service"
                                        handleClick={() =>
                                          handleAddAnotherService(arrayHelpers)
                                        }
                                        disabled={isButtonAddDisabled}
                                        className="w-100"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          handleSaveDraft();
          nextForm();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={isPhotoLoading || isLoading}
      />
    </>
  );
};

ServiceHistoryForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default ServiceHistoryForm;
