import { get } from 'lodash';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import {
  CLEAR_COMPANY_DETAILS_SUCCESS,
  CLEAR_STRIPE_VALIDATION_MESSAGE,
  CLEAR_VALIDATION_ERRORS_SUCCESS,
  COMPLETE_COMPANY_DETAILS_SUCCESS,
  GET_FEES_SETTINGS,
  VALIDATE_MAIN_PROFILE_FAILURE,
  VALIDATE_ORGANIZATION_FAILURE,
} from './RegisterReducer';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { confirmStripeCardSetup } from '../common/helpers/confirmStripeCardSetup';

const REGISTRATION_API_ENDPOINT = '/registration';
const COMPANY_PROFILE_API_ENDPOINT = '/company/profile';
const GET_STRIPE_CLIENT_SECRET_API_ENDPOINT = '/registration/stripe-client-secret';
const FEES_SETTINGS = '/fees-settings';

export const getFeesSettings = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${REGISTRATION_API_ENDPOINT}${FEES_SETTINGS}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_FEES_SETTINGS,
          payload: res?.data?.data,
        });
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const validateOrganizationRole = (organizationRole, successCallback) => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(`${REGISTRATION_API_ENDPOINT}/validate-organization-role`, { organizationRole })
    .then(res => {
      if (res.status === 200) {
        successCallback();
      }
    })
    .catch(err => {
      if (get(err, 'response.status', null) === 412) {
        dispatch(showAlert('Role is required', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateOrganization = (values, successCallback) => dispatch => {
  const {
    organizationRole,
    vatNumber,
    officialCompanyName,
    tradeName,
    companyRegisterNumber,
    postalCodeId,
    address,
    address2,
    city,
    county,
    phoneNumber,
    mobileNumber,
    website,
  } = values;
  const data = {
    organizationRoleForm: {
      organizationRole,
    },
    organizationForm: {
      vatNumber,
      officialCompanyName: officialCompanyName?.trim() || null,
      tradeName: tradeName?.trim() || null,
      companyRegisterNumber: companyRegisterNumber?.trim() || null,
      postalCodeId: postalCodeId?.value || null,
      address: address?.trim() || null,
      address2: address2?.trim() || null,
      city: city?.trim() || null,
      county: county?.trim() || null,
      phoneNumber: phoneNumber || null,
      mobileNumber: mobileNumber?.trim() || null,
      website: website?.trim() || null,
    },
  };
  dispatch(showLoader());
  return apiClient
    .post(`${REGISTRATION_API_ENDPOINT}/validate-organization`, data)
    .then(res => {
      if (res.status === 200) {
        successCallback();
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        return dispatch({
          type: VALIDATE_ORGANIZATION_FAILURE,
          payload: err?.response?.data?.data,
        });
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateMainProfile = (values, successCallback) => dispatch => {
  const {
    firstName,
    lastName,
    email,
    password,
    passwordRepeat,
    position,
    acceptTos,
    acceptNewsletter,
  } = values;

  const data = {
    firstName,
    lastName,
    email,
    password,
    passwordRepeat,
    position: position.value,
    acceptTos,
    acceptNewsletter,
  };
  dispatch(showLoader());
  return apiClient
    .post(`${REGISTRATION_API_ENDPOINT}/validate-main-profile`, data)
    .then(res => {
      if (res.status === 200) {
        successCallback();
      }
    })
    .catch(err => {
      if (get(err, 'response.status', null) === 412) {
        dispatch({ type: VALIDATE_MAIN_PROFILE_FAILURE, payload: err?.response?.data?.data });
        dispatch(showAlert('Invalid values', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const register = (values, successCallback, stripeCustomerId = null) => dispatch => {
  const {
    organizationRole,
    vatNumber,
    officialCompanyName,
    tradeName,
    companyRegisterNumber,
    postalCode,
    address,
    address2,
    city,
    county,
    phoneNumber,
    mobileNumber,
    website,
    firstName,
    lastName,
    email,
    password,
    passwordRepeat,
    position,
    acceptTos,
    acceptNewsletter,
  } = values;
  const data = {
    organizationRoleForm: {
      organizationRole,
    },
    organizationForm: {
      vatNumber,
      officialCompanyName: officialCompanyName?.trim() || null,
      tradeName: tradeName?.trim() || null,
      companyRegisterNumber: companyRegisterNumber?.trim() || null,
      postalCode: postalCode?.trim() || null,
      address: address?.trim() || null,
      address2: address2?.trim() || null,
      city: city?.trim() || null,
      county: county?.trim() || null,
      phoneNumber: phoneNumber || null,
      mobileNumber: mobileNumber?.trim() || null,
      website: website?.trim() || null,
    },
    mainProfileForm: {
      firstName,
      lastName,
      email,
      password,
      passwordRepeat,
      position: position.value,
      acceptTos,
      acceptNewsletter,
    },
    creditCardForm: stripeCustomerId
      ? {
          stripeCustomerId,
        }
      : null,
  };
  dispatch(showLoader());
  return apiClient
    .post(REGISTRATION_API_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        successCallback();
      }
    })
    .catch(err => {
      if (get(err, 'response.status', null) === 412) {
        dispatch(showAlert('Invalid values', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const completeCompanyDetails = (
  values,
  companyNumber,
  organizationRole,
  successCallback,
) => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(COMPANY_PROFILE_API_ENDPOINT, { companyNumber })
    .then(res => {
      if (res.status === 200) {
        if (typeof successCallback === 'function') {
          successCallback(res.data?.data);
        }
        dispatch({
          type: COMPLETE_COMPANY_DETAILS_SUCCESS,
          payload: {
            companyDetails: values,
            officialCompanyName: res?.data?.data?.officialCompanyName,
            companyNumber,
            organizationRole,
          },
        });
      }
    })
    .catch(err => {
      if (err?.response?.status === 429) {
        return dispatch(
          showAlert(
            'Too many requests were sent to the server. Wait a minute and try again.',
            AlertVariant.DANGER,
          ),
        );
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearValidationErrors = () => dispatch => {
  dispatch({ type: CLEAR_VALIDATION_ERRORS_SUCCESS });
};
export const clearCompanyDetails = () => dispatch => {
  dispatch({ type: CLEAR_COMPANY_DETAILS_SUCCESS });
};

export const getStripeClientSecret = (
  values,
  stripe,
  elements,
  registerCallback,
) => dispatch => {
  dispatch(showLoader());
  dispatch(clearStripeValidationMessage());

  return apiClient
    .get(GET_STRIPE_CLIENT_SECRET_API_ENDPOINT, {
      timeout: 10000,
    })
    .then(async res => {
      if (res.status === 200) {
        let error = false;
        if (values.cardholder) {
          const stripeResponse = await confirmStripeCardSetup(
            stripe,
            elements,
            res,
            values,
            dispatch,
          );

          if (stripeResponse?.error) {
            error = true;
          }
        }
        if (!error) {
          registerCallback(res?.data?.data?.stripeCustomerId);
        }
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearStripeValidationMessage = () => dispatch => {
  dispatch({ type: CLEAR_STRIPE_VALIDATION_MESSAGE });
};
