import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo-2.png';
import { InformationForUsersList } from '../constants/FooterNavList';
import RouterPaths from '../constants/RouterPaths';
import CommonDownloadIcons from '../common/CommonDownloadIcons';

const FooterNav = () => {
  return (
    <div className="footer-nav">
      <Row className="footer-nav__row d-flex justify-content-between">
        <Col
          md={3}
          className="footer-nav__row-item d-flex align-items-center justify-content-center flex-column"
        >
          <Row>
            <Col className="text-center">
              <Link to={RouterPaths.DASHBOARD}>
                <img
                  className="footer-logo"
                  src={logo}
                  alt="Page logo - itrade30"
                  width="125px"
                />
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <p className="m-0 pt-3 fw-400 text-sm footer-nav__rights">
                Auction4Dealers | All Rights Reserved 2024
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <ul className="footer-nav__list">
            <li
              key={InformationForUsersList.privacyPolicy.path}
              className="footer-nav__list-item"
            >
              <Link
                to={InformationForUsersList.privacyPolicy.path}
                className="footer-nav__list-item-link"
              >
                <span className="text-uppercase">
                  {InformationForUsersList.privacyPolicy.label}
                </span>
              </Link>
            </li>
            <li
              key={InformationForUsersList.termsAndConditions.path}
              className="footer-nav__list-item"
            >
              <Link
                to={InformationForUsersList.termsAndConditions.path}
                className="footer-nav__list-item-link"
              >
                <span className="text-uppercase">
                  {InformationForUsersList.termsAndConditions.label}
                </span>
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={3}>
          <ul className="footer-nav__list">
            <li key={InformationForUsersList.faq.path} className="footer-nav__list-item">
              <Link
                to={InformationForUsersList.faq.path}
                className="footer-nav__list-item-link"
              >
                <span className="text-uppercase">{InformationForUsersList.faq.label}</span>
              </Link>
            </li>
            <li key={InformationForUsersList.contactUs.path} className="footer-nav__list-item">
              <Link
                to={InformationForUsersList.contactUs.path}
                className="footer-nav__list-item-link"
              >
                <span className="text-uppercase">
                  {InformationForUsersList.contactUs.label}
                </span>
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={2} className="footer-nav__download-container">
          <CommonDownloadIcons />
        </Col>
      </Row>
    </div>
  );
};

export default FooterNav;
