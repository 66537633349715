import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionSellingPersonInformation = ({ item }) => {
  const { organizationContact } = item?.seller;

  return (
    <>
      <Row
        className="main-description-container__section-title mt-4"
        id="vehicle-sellers-information"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Seller&apos;s Information
        </Col>
      </Row>
      <Row
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
          }}
        >
          <Row className="mt-3 summary__selling-person-list text-base">
            <p className="m-0 p-0 fw-700">Contact details:</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">
              {item?.seller.firstName && item?.seller.lastName
                ? `${item?.seller.firstName} ${item?.seller.lastName}`
                : '-'}
            </p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{item?.seller.email || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{item?.seller.phoneNumber || '-'}</p>
          </Row>
          <Row className="mt-3 summary__selling-person-list text-base">
            <p className="m-0 p-0 fw-700">Vehicle location:</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{item?.location?.address || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{item?.location?.address2 || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">
              {item?.location?.postalCode?.postcode || '-'}{' '}
              {item?.location?.postalCode?.postcode && item?.location?.city
                ? item?.location?.city
                : ''}
            </p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{item?.location?.county || '-'}</p>
          </Row>
        </div>
        <div
          style={{
            marginLeft: '16px',
          }}
        >
          <Row className="mt-3 summary__selling-person-list text-base">
            <p className="m-0 p-0 fw-700">Dealer&apos;s information:</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{organizationContact?.tradeName || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">
              {organizationContact?.location?.address || '-'}
              {organizationContact?.location?.address &&
              organizationContact?.location?.address2
                ? ` ${organizationContact?.location?.address2}`
                : ''}
            </p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">
              {organizationContact?.location?.postalCode?.postcode &&
              organizationContact?.location?.city
                ? `${organizationContact?.location?.postalCode?.postcode} ${organizationContact?.location?.city}`
                : ''}
            </p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{organizationContact?.location?.county || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">{organizationContact?.email || '-'}</p>
          </Row>
          <Row className="summary__selling-person-list text-base">
            <p className="m-0 fw-600">
              {organizationContact?.phoneNumber || '-'}
              {organizationContact?.phoneNumber && organizationContact?.landlinePhoneNumber
                ? ` / ${organizationContact?.landlinePhoneNumber}`
                : ''}
            </p>
          </Row>
        </div>
      </Row>
    </>
  );
};

AuctionSellingPersonInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionSellingPersonInformation;
