import React from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';
import { getDraft, saveDescriptionAndSettingsDraft } from '../AddVehicleActions';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import AddVehicleFooter from '../AddVehicleFooter';

const AddDescriptionForm = ({ form, prevForm, handleSubmit, nextForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );

  const id = useSelector(state => state.addVehicle.get('id'));
  // const [ref, setRef] = useState(null);

  const handleSaveDraft = () => {
    const { description, defaultDescription } = form?.values;

    const data = {
      ...descriptionAndSettings,
      description,
      defaultDescription,
    };

    dispatch(saveDescriptionAndSettingsDraft(data, () => dispatch(getDraft(id))));
    form.setErrors({});
  };

  return (
    <>
      <AddVehicleHeader
        saveDraftButtonHandler={handleSaveDraft}
        saveDraftButtonDisabled={isLoading}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Description</h5>
            <p className="text-sm mt-2">
              Add an accurate detailed description of the vehicle and its condition.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off" className="text-left">
          <Row className="mt-3">
            <Col>
              <FormInputField
                label="Use your own words to describe the vehicle and its condition in more detail"
                type={FormInputFieldType.TEXTAREA}
                id="add-description__user-description"
                name="description"
                setRef={() => {}}
                required
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <FormInputField
                label="Please add a default text that will be added to all your vehicle listings"
                type={FormInputFieldType.TEXTAREA}
                id="add-description__user-default-description"
                name="defaultDescription"
                setRef={() => {}}
              />
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          handleSaveDraft();
          nextForm();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={isLoading}
      />
    </>
  );
};

AddDescriptionForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AddDescriptionForm;
