import React, { useCallback, useState } from 'react';
import { Navbar, NavbarToggler, Collapse, NavItem, Nav } from 'reactstrap';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery } from 'search/SearchActions';
import RouterPaths from '../../../constants/RouterPaths';
import { logoutUser } from '../../../auth/AuthActions';
import NavbarLoader from '../NavbarLoader/NavbarLoader';
import { InformationForUsersList } from '../../../constants/FooterNavList';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';

import logo from '../../../assets/img/logo.png';
import carIcon from '../../../assets/img/icons/car.svg';
import { ACTIVE_ROUTES_MAPPING } from '../../../constants/ActiveRouts';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const roles = {
    buyer: useSelector(state => state.auth.get('isBuyer')),
    seller: useSelector(state => state.auth.get('isSeller')),
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = id => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      history.push(`/#${id}`);
    }
  };

  const isActiveRoute = useCallback(
    route => {
      return (
        ACTIVE_ROUTES_MAPPING[location.pathname] &&
        ACTIVE_ROUTES_MAPPING[location.pathname] === route
      );
    },
    [location],
  );

  return (
    <header className={`styled-header ${isAuthenticated ? 'header-auth' : ''}`}>
      <Navbar expand="lg" className="header__navbar h-100">
        <Link
          to={RouterPaths.HOMEPAGE}
          onClick={() => dispatch(setSearchQuery(''))}
          className="page-logo-link"
        >
          <img src={logo} alt="logo" />
        </Link>

        <>
          {isAuthenticated ? (
            <Collapse isOpen={isOpen} navbar className="header-navbar-wrapper h-100">
              <Nav
                navbar
                className="d-flex flex-row align-items-center justify-content-center header-nav w-100 h-100"
              >
                <NavItem className="header-nav__item h-100 mx-auto">
                  <NavLink
                    to={RouterPaths.MY_VEHICLES}
                    className={`d-flex justify-content-center align-items-center h-100 ${
                      isActiveRoute(RouterPaths.DASHBOARD) ? 'active' : ''
                    }`}
                  >
                    <span className="text-uppercase">DASHBOARD</span>
                  </NavLink>
                  <NavLink
                    to={RouterPaths.SEARCH}
                    onClick={e => {
                      e.preventDefault();
                      dispatch(setSearchQuery(''));
                    }}
                    className="h-100 d-flex justify-content-center align-items-center h-100"
                  >
                    LIVE AUCTIONS
                  </NavLink>
                  <NavLink
                    to={RouterPaths.NOTIFICATIONS}
                    className="h-100 d-flex justify-content-center align-items-center h-100"
                  >
                    NOTIFICATIONS
                  </NavLink>
                  <NavLink
                    to={RouterPaths.SETTINGS}
                    className={`d-flex justify-content-center align-items-center h-100 ${
                      isActiveRoute(RouterPaths.SETTINGS) ? 'active' : ''
                    }`}
                  >
                    SETTINGS
                  </NavLink>
                </NavItem>
                <NavItem className="header-nav__item ml-auto d-flex">
                  {roles?.seller ? (
                    <CommonButton
                      className="text-sm"
                      handleClick={() => {
                        window.location.href = RouterPaths.ADD_VEHICLE; // window.location is intentional and required here.
                      }}
                      variant={CommonButtonVariants.SUCCESS}
                      label="ADD VEHICLE"
                      icon={carIcon}
                      iconAlt="Add Vehicle"
                      iconLeft={true}
                    />
                  ) : (
                    ''
                  )}
                  <CommonButton
                    className="ml-3 text-sm"
                    handleClick={() => {
                      dispatch(logoutUser());
                    }}
                    variant={CommonButtonVariants.OUTLINED}
                    label="LOG OUT"
                  />
                </NavItem>
              </Nav>
            </Collapse>
          ) : (
            <Collapse isOpen={isOpen} navbar className="header-navbar-wrapper h-100">
              <Nav
                navbar
                className="d-flex flex-row align-items-center justify-content-center header-nav w-100 h-100"
              >
                <NavItem className="header-nav__item mx-auto d-flex justify-content-between h-100">
                  <NavLink
                    to="#buy-section"
                    onClick={e => {
                      e.preventDefault();
                      handleNavClick('buy-section');
                    }}
                    className="font-weight-bold h-100 d-flex justify-content-center align-items-center h-100 scroller"
                  >
                    BUY
                  </NavLink>
                  <NavLink
                    to="#sell-section"
                    onClick={e => {
                      e.preventDefault();
                      handleNavClick('sell-section');
                    }}
                    className="font-weight-bold d-flex justify-content-center align-items-center h-100 scroller"
                  >
                    SELL
                  </NavLink>
                  <NavLink
                    to={InformationForUsersList.contactUs.path}
                    className="font-weight-bold d-flex justify-content-center align-items-center h-100"
                  >
                    <span className="text-uppercase font-weight-bold">
                      {InformationForUsersList.contactUs.label}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem className="ml-auto header-nav__item d-flex">
                  <NavLink
                    to={RouterPaths.REGISTER}
                    className="common-button outline-primary auth-button"
                    activeClassName="auth-button--active"
                  >
                    Register
                  </NavLink>
                  <NavLink
                    to={RouterPaths.LOGIN}
                    className="common-button primary-button auth-button ml-2"
                    activeClassName="auth-button--active"
                  >
                    Log In
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          )}
        </>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
      </Navbar>
      <NavbarLoader />
    </header>
  );
};

export default Header;
