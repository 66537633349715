import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AddPhotoSummary from './AddPhotoSummary';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import { getDraft, saveAddPhotoDraft, validateMedia } from '../AddVehicleActions';
import AddVehicleFooter from '../AddVehicleFooter';

const AddPhotoSummaryPage = ({ prevForm, nextForm }) => {
  const id = useSelector(state => state.addVehicle.get('id'));
  const dispatch = useDispatch();
  const photos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  const handleNextButton = () => {
    if ((photos?.filter(el => el?.fileUrl) || []).length >= REQUIRED_PHOTOS_AMOUNT) {
      dispatch(validateMedia(() => nextForm()));
    } else {
      nextForm();
    }
  };

  function handleSaveDraftButton() {
    dispatch(saveAddPhotoDraft()).then(() => dispatch(getDraft(id)));
  }

  return (
    <>
      <AddVehicleHeader saveDraftButtonHandler={handleSaveDraftButton} />
      <AddVehicleContentWrapper>
        <AddPhotoSummary prevForm={prevForm} nextForm={nextForm} />
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          handleSaveDraftButton();
          nextForm();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={
          isLoading
          // (photos?.filter(el => el?.fileUrl) || []).length < REQUIRED_PHOTOS_AMOUNT
        }
      />
    </>
  );
};

AddPhotoSummaryPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddPhotoSummaryPage;
