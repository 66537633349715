import React, { useEffect, useState } from 'react';
import { Row, Col, Progress } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import RouterPaths from '../../../constants/RouterPaths';
import { formatTimeZero, getTimeParts } from '../../utils/date/Date.utils';
import defImage from '../../../assets/img/vehicles/car-default-photo.svg';

const AuctionPreview = ({ dateStart, dateEnd }) => {
  const [durationTime, setDurationTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [progressValue, setProgressValue] = useState(0);

  const updateTime = () => {
    const end = moment(dateEnd);
    const start = dateStart ? moment(dateStart) : moment();
    const now = moment();

    if (end && start && (end > now || start > end)) {
      const endDiff = end.diff(now);
      // const endDuration = moment.duration(endDiff);

      const startDiff = now.diff(start);
      const totalDiff = end.diff(start);
      const startDuration = moment.duration(startDiff);

      const progress = Math.floor((startDuration.asMilliseconds() / totalDiff) * 100);
      setProgressValue(progress > 100 ? 100 : progress);

      const { days, hours, minutes, seconds } = getTimeParts(endDiff);
      setDurationTime({
        days,
        hours,
        minutes: formatTimeZero(minutes),
        seconds: formatTimeZero(seconds),
      });
    } else {
      setDurationTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });

      setProgressValue(100);
    }
  };

  useEffect(() => {
    const int = setInterval(updateTime, 1000);
    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="auction-preview w-100">
      <Row>
        <Col
          sm={12}
          md={6}
          className="d-flex auction-preview__title-wrapper justify-content-center align-items-center pr-5"
        >
          <div>
            <p className="text-xl font-weight-bold w-100 m-0">Auction Preview</p>
            <p className="fw-400 text-xs w-100 m-0">THIS LIVE AUCTION ENDS IN</p>
          </div>
        </Col>
        <Col
          sm={12}
          md={6}
          className="d-flex auction-preview__timer-wrapper justify-content-center align-items-center lp-5"
        >
          <div className="w-100 auction-preview__timer">
            <Row md={12}>
              <Col
                sm={3}
                className="d-flex justify-content-center flex-column align-items-center"
              >
                <span className="color-primary font-weight-bold text-2xl d-inline-block">
                  {durationTime.days}
                </span>
                <span className="d-inline-block text-2xs">DAYS</span>
              </Col>
              <Col
                sm={3}
                className="d-flex justify-content-center flex-column align-items-center"
              >
                <span className="color-primary font-weight-bold text-2xl d-inline-block">
                  {durationTime.hours}
                </span>
                <span className="d-inline-block text-2xs">HOURS</span>
              </Col>
              <Col
                sm={3}
                className="d-flex justify-content-center flex-column align-items-center"
              >
                <span className="color-primary font-weight-bold text-2xl d-inline-block">
                  {durationTime.minutes}
                </span>
                <span className="d-inline-block text-2xs">MINUTES</span>
              </Col>
              <Col
                sm={3}
                className="d-flex justify-content-center flex-column align-items-center"
              >
                <span className="color-primary font-weight-bold text-2xl d-inline-block">
                  {durationTime.seconds}
                </span>
                <span className="d-inline-block text-2xs">SECONDS</span>
              </Col>
            </Row>
            <Row md={12}>
              <Progress
                max={100}
                value={progressValue}
                className="auction-preview__timer-progress"
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const HotAuction = ({ auctionData }) => {
  return (
    <Row className="h-100 w-100">
      <Col
        xs={12}
        md={6}
        lg={6}
        className="p-0 d-flex justify-content-center align-items-center"
      >
        <img
          src={auctionData?.imageUrl || defImage}
          alt="auction-vehicle"
          className="w-100 h-100"
          style={{
            objectFit: 'cover',
            maxHeight: '500px',
          }}
        />
      </Col>

      <Col
        xs={12}
        md={6}
        lg={6}
        className="p-0 m-auto d-flex justify-content-around align-items-center"
      >
        <div className="hot-auction-container">
          <Row md={12} className="m-auto">
            <Col md={12} className="p-0">
              <p className="color-light-primary text-xl font-weight-bolder mb-2">
                HOT AUCTION
              </p>
              <p className="color-primary text-3xl fw-700 mb-2">{auctionData?.title || ''}</p>
              <p className="fw-300 color-primary text-xl">
                {auctionData?.derivative || ''} {auctionData?.year || ''}
              </p>
            </Col>
          </Row>
          <Row md={12} className="hot-auction-container__preview ml-auto mr-auto">
            <Col md={12} className="p-0">
              <AuctionPreview
                dateEnd={auctionData?.endsOn}
                dateStart={auctionData?.launchedOn || moment().format()}
              />
            </Col>
          </Row>
          <Row md={12} className="hot-auction-container__actions ml-auto mr-auto">
            <Col sm={12} md={6} className="pl-0">
              <NavLink
                to={RouterPaths.LOGIN}
                className="common-button primary-button w-100"
                activeClassName="auth-button--active"
              >
                BID NOW
              </NavLink>
            </Col>
            <Col sm={12} md={6} className="pr-0">
              <NavLink
                to={RouterPaths.REGISTER}
                className="common-button outline-primary w-100"
                activeClassName="auth-button--active"
              >
                REGISTER TO BID
              </NavLink>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

HotAuction.propTypes = {
  auctionData: PropTypes.object.isRequired,
};

export default HotAuction;
